import React, { useState, useEffect } from 'react';
import { Container } from 'Layout';
import { Button } from 'components/Button';
import { TextField } from 'components/Form';
import { Modal } from 'components/Modal';
import { useFormik } from 'formik';
import { genrateSchema } from 'validation';
import { usePost } from 'hooks';
import { signInService, attemptService } from 'api';
import { Link, Router, useHistory } from 'react-router-dom';
import { LINKS } from 'utils';

function InitDataCollect({ open, setOpen, ...props }) {
  const { HEALTH_SCORE, TERMS, PRIVATE } = LINKS;

  const [initialValues, setInitialValues] = useState({
    email: '',
    phone: '',
  });

  const [checkBox, setCheckBox] = useState(false);
  const [signIn, { data, status }] = usePost(signInService);
  const [attempt, { data: dataAttempt, status: statusAttempt }] = usePost(
    attemptService
  );

  let history = useHistory();

  useEffect(() => {
    if (dataAttempt) {
      history.push({
        pathname: HEALTH_SCORE,
        // search: '/score',
        state: { data: JSON.stringify(dataAttempt) },
      });
    }
  }, [dataAttempt]);

  useEffect(() => {
    if (data && data.user) {
      props.submitResponse.user_id = data.user.id;
      attempt({ variables: props.submitResponse });
    }
    if (data && data.message) {
      alert(data.message);
    }
  }, [data]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let { email, phone } = values;
      signIn({
        variables: {
          user: { email, phone },
        },
      });
    },

    validationSchema: genrateSchema(initialValues),
  });
  console.log(errors, 'aqws');
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="pt-60 px-47">
        <div className="modal-heading mx-20 mb-20 sm:mx-40">
          Enter your details to see your result
        </div>
        <div className="content text-center mx-20 mb-30 sm:ml-46 sm:mr-43">
          Click the box if you are happy for us to email you
        </div>
        <div className="mb-20">
          <TextField
            border="border"
            icon="email"
            height="59"
            indent="59"
            name="email"
            placeholder="Email"
            type="text"
            onChange={handleChange}
            handleBlur={handleBlur}
            value={values.email}
            error={errors.email}
          />
        </div>
        <div className="mb-40">
          <TextField
            border="border"
            icon="phone"
            height="59"
            indent="59"
            name="phone"
            placeholder="phone"
            type="number"
            onChange={handleChange}
            handleBlur={handleBlur}
            value={values.phone}
            error={errors.phone}
          />
        </div>
        <div className="mb-20">
          <input
            id="1"
            type="checkbox"
            name="checkBox"
            onChange={() => setCheckBox((prev) => !prev)}
            checked={checkBox}
          />
          <label className="pl-20" for="1">
            I agree with the{' '}
            <Link to={TERMS} target="_blank" className="underline">
              terms and condition
            </Link>{' '}
            and{' '}
            <Link to={PRIVATE} target="_blank" className="underline">
              {' '}
              privacy policy
            </Link>
          </label>
          {!checkBox ? (
            <div className=" ml-10 text-sm text-red">CheckBox is required</div>
          ) : null}{' '}
        </div>

        <div className="flex justify-center pb-80">
          <Button
            value="confirm"
            color="green"
            width="200"
            height="66"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}

export default InitDataCollect;
