import React, { useEffect } from 'react';
import { TermsPrivacy } from 'components/TermsPrivacy';
import { Container } from 'Layout';
import { sendPageView } from 'utils/sendPageView';

function Terms() {
  useEffect(() => sendPageView(window.location.pathname), []);
  return (
    <Container>
      <TermsPrivacy heading="terms-conditions" />
    </Container>
  );
}

export default Terms;
