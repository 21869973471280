import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import { LINKS } from 'utils';

function MobileSidebar({ isOpenMenu, setIsOpenMenu, setOpen }) {
  const { OVERVIEW, SUPPORT, LEARN_MORE, FAQ, ORDER_DNA } = LINKS;

  return (
    <Menu
      width={'100%'}
      //   height={'100%'}
      id="mbl-menu"
      className="mbl-menu"
      overlayClassName="mbl-menu-overlay"
      isOpen={isOpenMenu}
      onClose={() => {
        setIsOpenMenu(false);
      }}
    >
      <div className="bg-green pl-20 h-100%">
        <div className="text-18 py-40 font-white font-medium font-display text-white">
          <Link to={OVERVIEW}>
            <div className="mb-10">Start Making Progress</div>
          </Link>
          <Link to={SUPPORT}>
            <div className="mb-10">Progress With me</div>
          </Link>
          <Link to={LEARN_MORE}>
            <div className="mb-10">Learn More </div>
          </Link>
          <Link to={FAQ}>
            <div>Testimonials & FAQ</div>
          </Link>
        </div>
        <Link to={ORDER_DNA}>
          <Button
            value="Order my DNA kit"
            color="blue"
            width="200"
            height="66"
            onClick={() => {
              setIsOpenMenu(false);
            }}
          />
        </Link>
      </div>
    </Menu>
  );
}

export default MobileSidebar;
