import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-07411BDCWM', {
  legacyDimensionMetric: false,
  gtagOptions: { send_page_view: true },
});
console.log('v1.0.0');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
